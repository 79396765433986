import React, { useState, useRef } from "react"
import styled from "@emotion/styled"
import Chevron from "../Chevron/Chevron"

const Accordion = props => {
  const [height, setHeight] = useState("0px")
  const [active, setActive] = useState(false)

  const content = useRef(null)

  function toggleAccordion() {
    setActive(!active)
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`)
  }

  return (
    <AccordionContainer>
      <Button onClick={toggleAccordion} active={active}>
        <Chevron active={active} width={10} fill="#5558ea" />
        <Title>{props.title}</Title>
      </Button>
      <Content ref={content} height={height}>
        <Text dangerouslySetInnerHTML={{ __html: props.content }} />
      </Content>
    </AccordionContainer>
  )
}
export default Accordion

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 18px 8px;
  border-radius: 8px;
  margin-bottom: 20px;

  :last-of-type {
    margin-bottom: 0px;
  }
`
const Button = styled.button`
  background-color: #ffffff;
  color: #5558ea;
  cursor: pointer;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.6s ease;
  text-align: left;
  border-radius: ${props => (props.active ? "5px 5px 0px 0px" : "5px")};
  transition: border-radius 0.7s ease;
  /* z-index: 1; */

  :hover {
    color: #4b4b65;

    path {
      fill: #5558ea;
    }
  }
`

const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  margin-left: 15px;
`

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
  padding-top: 0;
  margin: 0;

  a {
    color: #5558ea;
    :hover {
      text-decoration: underline;
    }
  }
`
const Content = styled.div`
  background-color: #ffffff;
  max-height: ${props => props.height};
  overflow: hidden;
  transition: max-height 0.6s ease;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`
